const nlf = () => {
  const hoursTolltip = document.querySelector("#HOURS")
  if(hoursTolltip) {
    hoursTolltip.addEventListener("change", function(e){
      const showTooltip = document.querySelector(`.u-tooltip-phrase--${this.value}`)

      if(showTooltip) {
        const hideAllTolltips = document.querySelectorAll(".u-tooltip-phrase")

        hideAllTolltips.forEach(tooltip => {
          tooltip.classList.add("d-none")
        })

        showTooltip.classList.remove("d-none")
      }
    }, false);
  }


  const kwhInput = document.querySelector("#KWH")
  const tooltipKwh = document.querySelector(".u-tooltip-phrase--kwh-error")
  if(kwhInput) {
    kwhInput.addEventListener("blur", (e) => {
      if(e.target.value < 100 ) {
        if(tooltipKwh) {
          tooltipKwh.classList.remove("d-none")
        }
      } else if(e.target.value > 500 ) {
        if(tooltipKwh) {
          tooltipKwh.classList.remove("d-none")
        }
      }
      else {
        if(tooltipKwh) {
          tooltipKwh.classList.add("d-none")
        }
      }
    })
  }

  $('.nlf__select').change(function(){
    var text = $(this).find('option:selected').text()
    var $aux = $('<select/>').append($('<option/>').text(text))
    $(this).after($aux)
    $(this).width($aux.width())
    $aux.remove()
  }).change()


}

export default nlf;