import '../scss/app.scss';
import '../pug/index.pug';
import '@babel/polyfill';

import navbar from "./navbar";
import smoothScroll from "./smooth-scroll";
import inputFile from "./form-contatti";
import nlf from "./nlf";
// import formValidation from "./form-validation";

navbar()
smoothScroll()
nlf()

// Da usare se si vuole intraprendere una strada di validazione strettamente FE
// https://getbootstrap.com/docs/4.5/components/forms/#validation
// formValidation()

inputFile()


//Library
$(document).ready(function() {
    $("#show_hide_password a").on('click', function(event) {
        event.preventDefault();
        if($('#show_hide_password input').attr("type") == "text"){
            $('#show_hide_password input').attr('type', 'password');
            $('#show_hide_password i').addClass( "ion-eye" );
            $('#show_hide_password i').removeClass( "ion-eye-disabled" );
        }else if($('#show_hide_password input').attr("type") == "password"){
            $('#show_hide_password input').attr('type', 'text');
            $('#show_hide_password i').removeClass( "ion-eye" );
            $('#show_hide_password i').addClass( "ion-eye-disabled" );
        }
    });
  });
