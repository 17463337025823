const smootScroll = () => {
  document.querySelectorAll('.btn-scroll').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();

      document.querySelector(this.getAttribute('href')).scrollIntoView({
        behavior: 'smooth'
      });
    });
});
}

export default smootScroll