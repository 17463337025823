const inputFile = () => {
  const inputFileUpload = document.querySelector( '.form__inputfile' );
  const clearLabel = document.querySelector('.form__file__clear');
  const label = document.querySelector( '.form__file__name' );

  if(clearLabel) {
    clearLabel.addEventListener("click", () => {
      if(inputFileUpload.value) {
        console.log("input value", inputFileUpload.value)
        inputFileUpload.value = null;
        label.innerHTML = '';
        clearLabel.style.display = "none";
        console.log("input value", inputFileUpload.value)
      }
    })
  }

  if(inputFileUpload) {
    inputFileUpload.addEventListener( 'change', function( e ) {
      let fileName = '';

      if( this.files && this.files.length > 1 ) {
        fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
      }
      else {
        fileName = e.target.value.split( "\\" ).pop();
      }

      if( fileName ) {
        console.log("filename: ", fileName)
        label.innerHTML = fileName;
        clearLabel.style.display = "block";
      }
      else {
        label.innerHTML = "Nessun file selezionato";
        clearLabel.style.display = "none";
      }
    });
  }

}

export default inputFile